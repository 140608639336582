<template>
    <div>
        <el-form ref="queryForm" class="query-box"  v-model="queryParams" label-width="96px">
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="商户编号" prop="merchantNo">
                        <el-input v-model="queryParams.merchantNo"
                                  clearable
                                  placeholder="请输入商户编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="商户名称" prop="merSubName">
                        <el-input v-model="queryParams.merSubName"
                                  clearable
                                  placeholder="请输入商户名称"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="活动名称" prop="name">
                        <el-select v-model="queryParams.activityNo" size="small" placeholder="请选择活动名称" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(item,index) in activityList" :label="item.activityName" :value="item.activityNo" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="活动类型" prop="type">
                        <el-select v-model="queryParams.activityType"
                                   placeholder="请选择活动类型" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option v-for="(value, key) in dicts.ACTIVITY_TYPE" :label="value" :value="key" :key="key" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="通道" prop="channelNo">
                        <el-select v-model="queryParams.channelNo" placeholder="请选择通道" style="width: 100%" clearable> 
                                <el-option v-for="item in acqChannelList" :label="item.name" :value="item.channelNo" :key="item.channelNo"></el-option>
                            </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="一级机构编号" prop="name">
                        <el-input v-model="queryParams.oneOrgNo"
                                  clearable
                                  placeholder="请输入一级机构编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="直属机构编号" prop="name">
                        <el-input v-model="queryParams.orgNo"
                                  clearable
                                  placeholder="请输入机构编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="一代理编号" prop="name">
                        <el-input v-model="queryParams.oneAgentNo"
                                  clearable
                                  placeholder="请输入一级代理商编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="直属代理编号" prop="name">
                        <el-input v-model="queryParams.agentNo"
                                  clearable
                                  placeholder="请输入代理商编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="SN号" prop="name">
                        <el-input v-model="queryParams.beginSn"
                                  clearable
                                  placeholder="请输入SN起始号"
                                  style="width: 48%"
                                  size="small" @keyup.enter.native="handleQuery"/>
                                  <span style="display: inline-block;width: 4%;text-align: center;">-</span>
                        <el-input v-model="queryParams.endSn"
                                  clearable
                                  placeholder="请输入SN截止号"
                                  style="width: 48%"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="机具型号" prop="type">
                        <el-select v-model="queryParams.hardwareNo"
                                   placeholder="请选择机具型号" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option v-for="item in hardwareList" :key="item.value" :label="item.hardwareName" :value="item.hardwareNo"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="绑定时间">
                        <el-date-picker v-model="bindTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="开始考核时间">
                        <el-date-picker v-model="startTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="考核结束时间">
                        <el-date-picker v-model="endTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="操作类型" prop="type">
                        <el-select v-model="queryParams.operationType"
                                   placeholder="请选择操作类型" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option label="奖励" value="REWARD"/>
                            <el-option label="扣款" value="DEDUCTION"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="达标状态" prop="type" label-width="120px">
                        <el-select v-model="queryParams.targetStatus"
                                   placeholder="请选择达标状态" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option label="考核中" value="init"/>
                            <el-option label="已达标" value="success"/>
                            <el-option label="未达标" value="fail"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="达标时间">
                        <el-date-picker v-model="targetTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="入账状态" prop="type">
                        <el-select v-model="queryParams.accountStatus"
                                   placeholder="请选择入账状态" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option label="未入账" value="init"/>
                            <el-option label="已入账" value="success"/>
                            <el-option label="入账失败" value="fail"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="入账时间">
                        <el-date-picker v-model="accountTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
            </el-row> 
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'MERCHANT_ACTIVITY_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery" v-permission="'MERCHANT_ACTIVITY_QUERY'">
                    重置
                </el-button>
                <el-button icon="el-icon-download" size="mini"
                           @click="handleExport" v-permission="'MERCHANT_ACTIVITY_EXPORT'">
                    导出
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {PolicyApi,TerminalApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                bindTime: [],
                startTime: [],
                endTime: [],
                targetTime: [],
                accountTime: [],
                showCustomForm: false,
                hardwareList: [],
                activityList:[],
                acqChannelList:[],
            }
        },
        created() {
            TerminalApi.hardwareInfo.getHardwareList()
                .then(res=>{
                    if(res.success){
                        this.hardwareList = res.data
                    }
                })
            TerminalApi.queryInfo.getActivityInfo()
                .then(res=>{
                    if(res.success){
                        this.activityList = res.data
                    }
                })
        },
        async mounted() {
			const result = await TerminalApi.acqChannel.listAllChannel();
			this.acqChannelList = result && result.data || [];
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.bindTime = [];
                this.startTime = [];
                this.endTime = [];
                this.targetTime = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出机具活动",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await PolicyApi.merchantActivity.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                if(!this.bindTime){
                    this.bindTime =[]
                }
                if(!this.startTime){
                    this.startTime =[]
                }
                if(!this.endTime){
                    this.endTime =[]
                }
                if(!this.targetTime){
                    this.targetTime =[]
                }
                if(!this.accountTime){
                    this.accountTime =[]
                }
                
                let [bindTimeStart, bindTimeEnd] = this.bindTime;
                this.queryParams.bindTimeStart = bindTimeStart && (bindTimeStart + ' 00:00:00');
                this.queryParams.bindTimeEnd = bindTimeEnd && (bindTimeEnd + ' 23:59:59');

                let [startTimeStart, startTimeEnd] = this.startTime;
                this.queryParams.startTimeStart = startTimeStart && (startTimeStart + ' 00:00:00');
                this.queryParams.startTimeEnd = startTimeEnd && (startTimeEnd + ' 23:59:59');

                let [endTimeStart, endTimeEnd] = this.endTime;
                this.queryParams.endTimeStart = endTimeStart && (endTimeStart + ' 00:00:00');
                this.queryParams.endTimeEnd = endTimeEnd && (endTimeEnd + ' 23:59:59');

                let [targetTimeStart, targetTimeEnd] = this.targetTime;
                this.queryParams.targetTimeStart = targetTimeStart && (targetTimeStart + ' 00:00:00');
                this.queryParams.targetTimeEnd = targetTimeEnd && (targetTimeEnd + ' 23:59:59');

                let [accountTimeStart, accountTimeEnd] = this.accountTime;
                this.queryParams.accountTimeStart = accountTimeStart && (accountTimeStart + ' 00:00:00');
                this.queryParams.accountTimeEnd = accountTimeEnd && (accountTimeEnd + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
